<template>
  <div class="tag-mapping__layout">
    <div class="tag-mapping__title">
      <h3>
        {{ $t('routes.cloudCostManagementTagMapping') }}
      </h3>
    </div>
    <div class="tag-mapping__content">
      <div class="tag-mapping__project">
        <h4 class="mb-4">
          {{ $t('Projects') }}
        </h4>
        <div class="tag-mapping__text">
          {{ $t('mappingText', { type: 'project' }) }}
        </div>
        <CyBtnToggle
          :value="projectSelectionMode"
          :readonly="disabled"
          :items="$static.selectionOptions"
          class="my-4"
          @input="$emit('change-projects-selection-mode', $event)"/>
        <v-combobox
          v-if="projectSelectionMode === 'tagSelection'"
          v-model="$v.projectTags.$model"
          class="tag-mapping__select"
          multiple
          item-color="secondary"
          :disabled="disabled"
          :error-messages="projectErrors"
          :items="_.union(tagMappingTags, $v.projectTags.$model)"
          :label="$t('projectTag')"
          @blur="$v.projectTags.$touch(); emitIsValid();">
          <template #selection="{ item }">
            <CyTag
              variant="default"
              class="mt-1 mr-1"
              :icon-after="!disabled ? 'close' : null"
              @click-icon-after="projectTags = _.without(projectTags, item)">
              {{ item }}
            </CyTag>
          </template>
          <template #no-data>
            <div class="tag-select__no-data px-4 py-1">
              <div class="no-data__title">
                {{ $t('noData.title') }}
              </div>
              <span class="no-data__text">
                {{ $t('noData.text') }}
              </span>
            </div>
          </template>
        </v-combobox>
        <v-text-field
          v-if="projectSelectionMode === 'advanced'"
          v-model="$v.projectTags.$model"
          class="regex-field"
          prepend-inner-icon="more_vert"
          :disabled="disabled"
          :error-messages="projectErrors"
          :label="$t('regularExpression')"
          @blur="$v.projectTags.$touch(); emitIsValid();">
          <template #prepend-inner>
            <v-icon>
              more_vert
            </v-icon>
            <div class="d-flex align-center">
              /
            </div>
          </template>
          <template #append>
            <div class="d-flex align-center">
              /
            </div>
          </template>
        </v-text-field>
      </div>
      <div class="tag-mapping__environment">
        <h4 class="mb-4">
          {{ $t('Environments') }}
        </h4>
        <div class="tag-mapping__text">
          {{ $t('mappingText', { type: 'environment' }) }}
        </div>
        <CyBtnToggle
          :value="environmentSelectionMode"
          :readonly="disabled"
          :items="$static.selectionOptions"
          class="my-4"
          @input="$emit('change-environments-selection-mode', $event)"/>
        <v-combobox
          v-if="environmentSelectionMode === 'tagSelection'"
          v-model="$v.environmentTags.$model"
          class="tag-mapping__select"
          multiple
          item-color="secondary"
          :disabled="disabled"
          :error-messages="environmentErrors"
          :items="_.union(tagMappingTags, $v.environmentTags.$model)"
          :label="$t('environmentTag')"
          @blur="$v.environmentTags.$touch(); emitIsValid();">
          <template #selection="{ item }">
            <CyTag
              variant="default"
              class="mt-1 mr-1"
              :icon-after="!disabled ? 'close' : null"
              @click-icon-after="environmentTags = _.without(environmentTags, item)">
              {{ item }}
            </CyTag>
          </template>
          <template #no-data>
            <div class="tag-select__no-data px-4 py-1">
              <div class="no-data__title">
                {{ $t('noData.title') }}
              </div>
              <span class="no-data__text">
                {{ $t('noData.text') }}
              </span>
            </div>
          </template>
        </v-combobox>
        <v-text-field
          v-if="environmentSelectionMode === 'advanced'"
          v-model="$v.environmentTags.$model"
          class="regex-field"
          prepend-inner-icon="more_vert"
          :disabled="disabled"
          :error-messages="environmentErrors"
          :label="$t('regularExpression')"
          @blur="$v.environmentTags.$touch(); emitIsValid();">
          <template #prepend-inner>
            <v-icon>
              more_vert
            </v-icon>
            <div class="d-flex align-center">
              /
            </div>
          </template>
          <template #append>
            <div class="d-flex align-center">
              /
            </div>
          </template>
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import CyBtnToggle from '@/components/btn-toggle.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyCloudCostManagementTagMapping',
  components: {
    CyBtnToggle,
  },
  props: {
    tagMapping: {
      type: Object,
      required: true,
    },
    projectSelectionMode: {
      type: String,
      required: true,
    },
    environmentSelectionMode: {
      type: String,
      required: true,
    },
    tagMappingTags: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  validations () {
    return {
      projectTags: {
        required,
        validRegex: (value) => this.projectSelectionMode === 'advanced' ? this.isValidRegex(value) : true,
        regexHasMatches: (value) => this.projectSelectionMode === 'advanced' ? this.regexHasMatches(value) : true,
      },
      environmentTags: {
        required,
        validRegex: (value) => this.environmentSelectionMode === 'advanced' ? this.isValidRegex(value) : true,
        regexHasMatches: (value) => this.environmentSelectionMode === 'advanced' ? this.regexHasMatches(value) : true,
      },
    }
  },
  computed: {
    $static () {
      return {
        selectionOptions: [
          {
            key: 'tagSelection',
            value: 'tagSelection',
            text: this.$t('tagSelection'),
          },
          {
            key: 'advanced',
            value: 'advanced',
            text: this.$t('advanced'),
          },
        ],
      }
    },
    projectTags: {
      get () {
        const { project_regex: projectRegex, project_tags: projectTags = [] } = this.tagMapping

        if (this.projectSelectionMode === 'advanced') return projectRegex
        return projectTags
      },
      set (data) {
        const emitPayload = this.projectSelectionMode === 'advanced'
          ? { key: 'project_regex', data }
          : { key: 'project_tags', data }
        this.$emit('change', emitPayload)
        this.emitIsValid()
      },
    },
    environmentTags: {
      get () {
        const { environment_regex: environmentRegex, environment_tags: environmentTags = [] } = this.tagMapping

        if (this.environmentSelectionMode === 'advanced') return environmentRegex
        return environmentTags
      },
      set (data) {
        const emitPayload = this.environmentSelectionMode === 'advanced'
          ? { key: 'environment_regex', data }
          : { key: 'environment_tags', data }
        this.$emit('change', emitPayload)
        this.emitIsValid()
      },
    },
    projectErrors () {
      const errors = []
      const { $dirty, required, validRegex, regexHasMatches } = this.$v.projectTags
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (required && !validRegex) errors.push(this.$t('invalidRegex'))
      if (required && !regexHasMatches) errors.push(this.$t('noMatchingTags'))
      return errors
    },
    environmentErrors () {
      const errors = []
      const { $dirty, required, validRegex, regexHasMatches } = this.$v.environmentTags
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (required && !validRegex) errors.push(this.$t('invalidRegex'))
      if (required && !regexHasMatches) errors.push(this.$t('noMatchingTags'))
      return errors
    },
  },
  watch: {
    environmentSelectionMode () {
      this.emitIsValid()
    },
    projectSelectionMode () {
      this.emitIsValid()
    },
  },
  mounted () {
    this.$v.projectTags.$touch()
    this.$v.environmentTags.$touch()
    this.emitIsValid()
  },
  methods: {
    emitIsValid () {
      this.$emit('is-valid', _.every([this.projectErrors, this.environmentErrors], _.isEmpty))
    },
    isValidRegex (value) {
      try {
        RegExp(value)
      } catch (e) {
        return false
      }
      return true
    },
    regexHasMatches (regexString) {
      if (!this.isValidRegex(regexString)) return false
      const regex = new RegExp(regexString)
      return _.some(this.tagMappingTags, (tag) => regex.test(tag))
    },
  },
  i18n: {
    messages: {
      en: {
        bestPractices: 'Best practices',
        mappingText: 'Map your existing tags to the {type} dimension. You can map several tags to account for casing or other inconsistencies across your infrastructure, or use the advanced mode to match tags using regular expressions.',
        tagSelection: 'Tag selection',
        advanced: 'Advanced',
        environmentTag: 'Environment tag',
        projectTag: 'Project tag',
        regularExpression: 'Regular expression',
        invalidRegex: 'Please enter a valid regular expression',
        noMatchingTags: 'None of the tags match the provided regular expression',
        noData: {
          title: 'No tag matching your criteria',
          text: 'Review your search or press ENTER to confirm this one.',
        },
      },
      es: {
        bestPractices: 'Buenas prácticas',
        mappingText: 'Asigne sus tags existentes a la dimensión {type}. Puede mapear varios tags a su cuenta para carcasa u otras inconsistencias en su infraestructura, o utilizar el modo avanzado para hacer coincidir los tags mediante expresiones regulares.',
        tagSelection: 'Selección de tag',
        advanced: 'Avanzado',
        environmentTag: 'Tag de entorno',
        projectTag: 'Tag de proyecto',
        regularExpression: 'Expresión regular',
        invalidRegex: 'Por favor, ingrese una expresión regular válida',
        noMatchingTags: 'Ninguno de los tags coincide con la expresión regular proporcionada',
        noData: {
          title: 'Ningún tag que coincida con sus criterios',
          text: 'Revise su búsqueda o presione ENTER para confirmar esta.',
        },
      },
      fr: {
        bestPractices: 'Bonnes pratiques',
        mappingText: `Associez vos tags existans à la dimension {type}. Vous pouvez associer plusieurs tags pour prendre en compte la casse ou d'autres.incohérences dans votre infrastructure, ou utiliser le mode avancé pour associer des tags en utilisant une expression régulière`,
        tagSelection: 'Selection de tags',
        advanced: 'Avancé',
        environmentTag: `Tag d'environnemnt`,
        projectTag: 'Tag de projet',
        regularExpression: 'Expression régulière',
        invalidRegex: 'Veuillez saisir une expression régulière valide',
        noMatchingTags: `Aucun de vos tags ne correspond à l'expression régulière renseignée`,
        noData: {
          title: 'Aucun tag ne correspond à votre recherche',
          text: 'Modifiez votre recherche ou appuyez sur ENTRER pour confirmer celle-ci.',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-mapping {
  &__layout {
    display: flex;
  }

  &__title {
    width: 315px;
  }

  &__content {
    width: 490px;

    .regex-field {
      ::v-deep.v-input__append-inner {
        align-self: center;
      }
    }
  }
}

.tag-mapping__select {
  ::v-deep.v-select__selections {
    align-items: flex-start;
  }
}

.tag-select__no-data {
  max-width: 490px;

  .no-data {
    &__title {
      padding-bottom: 4px;
      font-weight: $font-weight-bolder;
    }

    &__title,
    &__text {
      color: cy-get-color("primary");
    }
  }
}
</style>
